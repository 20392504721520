<template>
  <div class="h-full flex flex-col justify-center items-center background-element w-4/5 xl:w-1/3 ">
    <AddStacks class="w-4/5 xl:w-full"/>
    <StackProgress class="mt-1 w-4/5 xl:w-full"/>
    <div v-if="lookAtPrizes" class="look-at-prizes flex flex-col items-center">
      <p class="look-at-prizes-text">Look at your <span class="block">prizes</span></p>
      <div class="triangle"></div>
    </div>
  </div>
</template>



<script>
// @ is an alias to /src
import AddStacks from '../components/AddStacks'
import StackProgress from '../components/StackProgress'



export default {
  name: 'Spin',
  
  data(){
    return {
      rotation: 0,
    }
  },
  
  components: {
    AddStacks,
    StackProgress
   
  },

  mounted() {

  this.$store.state.showLogo = true
  this.$store.state.navBarShow = true
  this.$store.state.startJewelAnimation = true
  
  },
 
  computed: {
    lookAtPrizes(){
      return this.$store.getters.lookAtPrizes
    }
    

  }
}
</script>

<style lang="scss">

.triangle {
  background-color: white;
  width: 2em;
  height: 2em;
  background-color : var(--accent-color);
  transform:rotate(45deg);
  margin-top:-1em;
  
}

span {
  font-size: 2em;
  margin-top:-0.4em
}

.look-at-prizes-text {
  background-color : var(--accent-color);
  color: var(--background-color);
  padding : 2em;
   border-radius: 1em;
  font-size: 0.7em;
  font-weight: 600;
}

.look-at-prizes {
  position: absolute;
  bottom: 0%;
  left: 50%;
  animation: animateUpAndDown 0.5s ease-in-out infinite alternate
}

@keyframes animateUpAndDown {

  0% {
    transform: translate(70%,-70%);

  }

  100% {
    transform: translate(70%,-80%);
  }
  
}


.background-element {
  font-size: var(--responsive-font-height);
  height: 100%;
  border-radius: 1em;
}

</style>
