<template>
  <div v-if="stackSize >= 3" class="flex flex-col justify-between items-center responsive-font-size spin-btn-enter">

    <div  class="spin_button_parent flex justify-center items-center " :class="{'enableSpinButton' : enableSpinButton}">
      <div class="animated-background" :style="greenProgress"></div>

      <button  :disabled="disableSpin" v-on:click="spinReadyCheck"  class="btn-spin flex justify-between items-center" >
        <!-- <div class="btn__spin_progress"></div> -->
        <div class="flex flex-col items-start justify-center">
          <p class="btn-spin-points-numbers">{{ points }}</p>
          <p class="btn-spin-points-text">POINTS</p>
        </div>

        <p class="spin-amount-number" :style="stackProgress">{{ spins }} SPINS</p>

        <!-- <div class="flex items-center"> -->
          
          <!-- <div  class="div__amountofspins flex items-center justify-center" >
            <div class="spin-amount-circle" ></div>
            
          </div> -->
          <!-- <p class="btn-spin-spin-text">SPINS</p> -->
        <!-- </div> -->

      </button>

    </div>

    <div class="flex justify-between w-full p__information">
      <p class="">50 points = 1 spin</p>
      <p class="z-10" :class="{'need-full-stack': needStacksPopUp}">stack size {{ stackSize }} of 3</p>
     
    </div> 

  </div>

</template>

<script>


export default {
  name: 'Introduction',
  props: {
    msg: String
  },
  
  data() {
    return {
      disableSpinButton: false,
      allSelectedStacks: []
    }
  },

  components: { 
    
  },

  methods : {
  
  spinReadyCheck() {
    if (this.stackSize === 3) {
      this.spinAndRemoveStacks()
    }else {
      this.$store.state.needStacksPopUp = true
      console.log(this.needStacksPopUp)
      this.disableSpinButton = true
      setTimeout(() => {
      this.$store.state.needStacksPopUp = false
      this.disableSpinButton = false
      console.log(this.$store.state.needStacksPopUp)
      }, 3000);
    }
  },

  sortBigToSmall(sortSelected){
    sortSelected.sort(function(a, b) {
    return b - a;
    });
  },
  
  removeStacks(remove) {
    var i = 0
    var allStacks = this.$store.state.allStacks
    for (i = 0; i < remove.length; i++){
      allStacks.splice(remove[i],1)
    }
  },
  
  spinAndRemoveStacks(){
    this.$router.push('/spin')
    this.$store.state.navBarShow = false
    var stack = this.$store.state.stackState
    
    var i = 0
    for (i = 0; i < stack.length; i++ ) {
      stack[i].state = false
      this.allSelectedStacks.push(stack[i].selected)
    }
      
     this.sortBigToSmall(this.allSelectedStacks)
     this.removeStacks(this.allSelectedStacks)
     this.allSelectedStacks = []
    
  }

  },

  computed: {
    // maybe try and do animations with class bindings, to seperate the intent from the detail
    disableSpin() {
      return this.disableSpinButton
    },
    
    stackProgress() {
      if (this.stackSize === 1) {
        return {
          '--circleOpacity': '0.33',
          animation : 'opacAnimate 0.4s ease-in-out forwards'
        }
      }else if (this.stackSize === 2) {
        return {
          '--circleOpacity': '0.66',
          animation : 'opacAnimate 0.4s ease-in-out forwards'
        }

      }else if (this.stackSize === 3) {
        return {
          '--circleOpacity': '1',
          animation : 'opacAnimate 0.4s ease-in-out forwards'
        }
      }
      return {
        backgroundColor: 'white',
        color: 'var(--spin-button-color)'
      }
    },
  
    // maybe try and do animations with class bindings, to seperate the intent from the detail
  greenProgress() {
    if (this.stackSize === 1) {
      return {
        '--greenHeight' : '1.68em',
        animation : 'greenProgress 0.4s ease-in-out forwards'
      }
    } else if (this.stackSize === 2) {
      return {
        '--greenHeight' : '3.37em',
        animation : 'greenProgress 0.4s ease-in-out forwards'
      }

    } else if (this.stackSize === 3) {
      return {
        '--greenHeight' : '6em',
        animation : 'greenProgress 0.4s ease-in-out forwards'
      }
    }
    return {
      //  backgroundColor: '#0083c2'
    }
  },

  
  points() {
   return this.$store.getters.totalPoints
  },
  spins() {
    return this.$store.getters.availableSpins
  },
  stackSize() {
    return this.$store.getters.stackSize
  },
  enableSpinButton(){
    return this.$store.getters.enableSpinButton
  },

  needStacksPopUp(){
    return this.$store.getters.needStacksPopUp
  }
  //...mapGetters(['points', 'spins', 'stackSize', 'enableSpinButton']) etc.
  
  }

}
</script>

<style lang="scss">

:root {
  --greenHeight : 81px;
  --circleOpacity : 0;
}




.spin-btn-enter{
// transform: scale(0,1);
animation: spin-btn-enter-animation 0.3s ease-out forwards;

}

@keyframes spin-btn-enter-animation {

    0% {
      transform: translate(0em,0.5em);
      // transform: scale(0,1);
      opacity: 0;
    }

    100% {
      transform: translate(0em,0em);
      // transform: scale(1,1);
      opacity: 1;
    }

}


.btn-spin-spin-text{
  font-size: 2.2em;
  color: white;
  font-weight: bold;
  background-color: var(--background-color);
  padding-left: 0.5em;
  padding-right: 0.5em;
  
}

.need-full-stack {
  font-size: 1em;
  border-radius: 0.6em;
  animation: showNeedFullStack 3s ease-in
  
}

@keyframes showNeedFullStack {
  
  0% {
    color: white;
    background-color: transparent;
  }
  10% {
    color: var(--background-color);
    font-size: 1.4em;
    // font-weight: bold;
    background-color:white;
    padding: 0.8em;
  }
  90% {
    color: var(--background-color);
    // font-weight: bold;
    font-size: 1.4em;
    background-color: white;
    padding: 0.8em;
  }
  100% {
    color: white;
    font-size: 1em;
    background-color: transparent;
  }
}


.spin-amount-number {
  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color:var(--spin-button-color);
  width: 5em;
  background-color: white;
  font-size:1.6em;
  font-weight: bold;
  // padding-left: 0.5em;
  // padding-right: 0.5em;
  padding: 0.4em;
  border-radius: 0.5em;
  // margin-bottom: 0.2em;
  transform-origin: center center;
}

.btn-spin-points-text {
  font-size: 1.2em;
  margin-top: -0.6em;
  margin-bottom: 0.1em;
}

.btn-spin-points-numbers {
  font-size: 1.4em;
  font-weight: bold;
  

}

.spin_button_parent {
  position: relative;
    z-index: 20;
    display: flex;
    width: 100%;
    height: 6em;    
    // border: 0.1em solid var(--primary-button-color);
    border-radius: 0.8em;
    margin-top: 1em;
    margin-bottom: 0.6em;
    // background-color: var(--secondary-color);
    // font-size: var(--responsive-font-height)
  

}

.enableSpinButton {
  animation: shadowAnimate 0.6s ease-in-out infinite forwards;
  transition: opacity .1s ease-in-out;
  opacity: 1;
  &:hover {
    opacity: 0.7
  }

}

@keyframes shadowAnimate {
  0% {
    transform: scale(1,1);
  }
  
  50% {
    transform: scale(0.98,0.98);
  }

  100% {
    transform: scale(1,1);
  }
}

.spin-amount-circle {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  // padding: 0.5em;
  
  width: 10em;
  height: 1.5em;
  color: white;
  background-color: var(--background-color);
  // font-size: 1em;
  // font-size: var(--responsive-font-height);
}


.animated-background {
    position: absolute;
    top: 0px; right: 0px; bottom: 0px; left: 0px;
    height: 0px;
    background-color: var(--spin-button-color);
    border-radius: inherit;
    
}

.btn-spin {
  position: relative;
  // z-index: 0;
  height: 6em;
  width: 100%;
  border-radius: inherit;
  color: white;
  box-sizing: border-box;
  background-color: var(--spin-button-color);
  $border: 0.2em;
  border: solid $border transparent;
  background-clip: padding-box;
  padding: 0.8em;
  // font-size: var(--responsive-font-height);

  &:focus {
	outline: none;
	// border-radius: 3em;
	}

}

.responsive-font-size {
  font-size: var(--responsive-font-height);
  user-select: none;

}

.div__amountofspins {
  // background-color: #0083c2;
  border-radius: 50%;
  // padding: 10px;
  // width: 5em;
  // height: 5em;
  color: var(--background-color);
  font-weight: bold;
  font-size: 2.2em;
  margin-right: 0em;
}

.p__information {
  color:var(--stack-color);
  font-size: 0.9em;
  padding-left: 0.3em;
  padding-right: 0.3em
}

@keyframes opacAnimate {

  0% {
    opacity: 1;
    transform: scale(1,1);
  }

  50% {
     transform: scale(0.85,0.85);
  }

  
  100% {
    // opacity: var(--circleOpacity);
    transform: scale(1,1);
  }
  
}


@keyframes greenProgress {

  0% {
    height: 0px;
  }
  
  100% {
    height: var(--greenHeight);
  }
}
</style>
