<template>
      
  <button  @click="addStack(index)" :disabled="selected" :class="{'opacity_50': selected, 'justify-between': isAddMode} " class="comp__stack flex items-center justify-center">
     <div class="flex justify-center items-center z-10 h-full">
     <img :src="jewelOnStack" alt="" class="jewel-size-in-stack">
     <!-- <div class="circle-back"></div> -->
     <p class="jewel-circle-text">+{{ points }}</p>
     </div>
     <div v-if="isAddMode" class="flex flex-col justify-center items-end w-1/2">
       <p class="name-font-size">{{ stackname }}</p>
       <p class="expire-font-size">expires in {{ expiration }} days</p>
    </div>
  </button>
  
</template>


<script>


export default {
  name: 'Introduction',
  props: {

    /*
    points: {
      type: Number,
      required: false/true
      default() {
        return 999999;
      }
    },
    */

    points: Number,
    expiration: Number,
    stackname: String,
    index: Number,
    selected: Boolean,

    isAddMode: {
      type: Boolean,
      default() {
        return false;
      }
    }

  },
  
  components: {
  },
  
  computed: {
      jewelOnStack(){

      if(this.points == 150){
        return this.jewel[0]
      } 
      else if(this.points == 100){
        return this.jewel[1]
      }
      else if(this.points == 50){
        return this.jewel[2]
      }
        return this.jewel[-1]
      },
      
      jewel(){
         return this.$store.getters.selectJewel
      }
  },
  
  methods: {
    addStack(){
      if (this.isAddMode) {
        this.$store.dispatch('addStack', {selected : this.index})
        this.$router.push('/Home')
        this.$store.state.navBarShow = true
      }else {
        this.$store.dispatch('changeStack', {change : this.index})
        // this.$router.push('/allstacks')
        this.$store.state.navBarShow = false
      }

    }
  }

}
</script>

<style lang="scss">

.name-font-size {
  text-align: right;
  font-size: 0.9em;
  font-weight: 600;
  width: 100%;
 
}

.expire-font-size {
  text-align: right;
  font-size: 0.7em;
  margin-top: -0.7em;
  width: 100%;
}

.jewel-size-in-stack {
  
  // width: 100%;
  // padding: 0.3em;
  // height: 100%;
  // align-self: flex-start;
  height: 6em;
  max-height: 100%;
}

.jewel-circle-text {
  position: absolute;
  // margin-top: 4.4em;
  font-size: 1em;
  font-weight: bold;
  color: black;
}

.circle-back {
position: absolute;
// margin-top: 4.4em;
width: 3em;
height: 1.6em;  
background-color: white;
border-radius: 15%;
}

.comp__stack {
  // border: 1px var(--secondary-color) solid;
  // margin-top: 0.5em;
  height: 100%;
  width: 100%;
  // padding-left: 1em;
  // padding-right: 1em;
  // border-radius: 0.8em;
  // background-color: white;
  color: var(--stack-color);
  font-size: var(--responsive-font-height);
  font-weight: normal;
  // transition: transform .2s ease-in-out;
  cursor: pointer;
  // &:not(.opacity_50):hover {
  //   transform: scale(0.98, 0.98);
   
  // }   
  
  &:focus {
	outline: none;
	// border-radius: 3em;
	}
	
  
  // &:not(.opacity_50) {
  //   cursor: not-allowed;
  // }   
}

.stack-background {
  position: absolute;
  width: auto;
  height: auto;
  background-color: turquoise;
  
}

// .comp__stack:hover {
//   transform: scale(0.95, 0.95);
// }

.opacity_50 {
  opacity: 0.3;
  font-weight: normal;
  // cursor: not-allowed;

}

</style>
