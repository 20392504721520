<template>
  <div id="app" class="flex flex-col justify-between items-center background content">
     <div v-if="startJewelAnimation" id="flying-jewels">
   <!-- <img id="test1" class="size" src="../../src/assets/svg_sapphire.svg" alt=""> -->
   
    </div>  
    <img v-if="showLogo" src="../src/assets/telkom_logo.png" class="img-size z-20 testAnimation" alt="">
    <router-view class="h-full w-full z-10 testAnimation"/>
    <NavBar class="w-full md:w-4/5 xl:w-1/3 z-20 " v-if="navBarShow"></NavBar>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../src/components/NavBar.vue'


export default {
  name: 'Home',
  
  data(){
    return {
      rotation: 0,
      // selectJewel: [ require("../src/assets/ruby.png") ,require("../src/assets/sapphire.png") , require("../src/assets/emerald.png")]
    }
  },
  
  components: {
    NavBar
  
  },
  

  
   methods: {

    flyingJewels() {
      const jewelSpace = document.getElementById('flying-jewels')
      const jewel = document.createElement('img')
      // console.log('jewel is created!')
     
            var size = Math.floor(Math.random() * 20)
      // jewel.src = "../src/assets/svg_sapphire.svg"
      
      var randomJewel = Math.floor(Math.random() * 3)
  

      jewel.setAttribute("src", this.jewelFlat[randomJewel])
      jewel.setAttribute("width", 20 + size + 'em')
      jewel.setAttribute("height", 20 + size + 'em')
     
      jewel.setAttribute("class", "jewel")
            
      jewel.style.top = Math.random() * innerHeight + 'px'
      jewel.style.left = Math.random() * innerWidth + 'px'
     
      
      jewelSpace.appendChild(jewel)

      setTimeout(() => {
        jewel.remove()
        // console.log('removed')
      }, 5000);

    },

    createJewels(){
      setInterval(this.flyingJewels, 300);

    }

  },
  
    mounted(){
        this.createJewels()
        // setTimeout(()=>{
        //   var jewel = document.getElementsByClassName('jewel')
        //   for (var i =0; i < jewel.length ; i++) {
        //   console.log(jewel)
        //   jewel[i].style.animationPlayState = 'paused'
        //   }
        // },4000)
        
        // if (this.$store.state.startJewelAnimation === true) {
        //   this.$store.state.startJewelAnimation = true
          
        // }
  },
  
  computed: {
    jewel(){
     return this.$store.getters.selectJewel
    },    
    
    jewelFlat(){
     return this.$store.getters.selectFlatJewel
    },    
    
    navBarShow() {
      return this.$store.getters.navBarShow
      
    },
  
    showLogo() {
      return this.$store.getters.showLogo
      
    },

    startJewelAnimation(){
      return this.$store.getters.startJewelAnimation
    }
  }
}
</script>

<style lang="scss">

.testAnimation {

transform-origin: center;
animation: bleh 0.1s ease-in-out

}

@keyframes bleh {
  
0% {
  opacity: 0;
  transform: scale(0.95,0.95);
}


100% {
  opacity: 1;
  transform: scale(1,1);
}

}


#flying-jewels {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.jewel {
  position: absolute;
  transform-origin: center;
  animation: jewelAnimation 5s ease-in-out forwards;
  // filter: blur(var(--randomBlur));
}

@keyframes jewelAnimation {
  
  0% {
    transform: scale(0) translateY(0%) rotate(0deg);
    // opacity: 1;
  }

  10% {
    opacity: 0.5;
  }
 
  90% {
    // transform: scale(1) translateY(300%);
    opacity: 0.5;
    
  }

  100%{
    transform: scale(1) translateY(300%) rotate(360deg);
    opacity: 0;  
  }
}


:root {
  
  --add-stack-background: white;
  --add-stack-color: #0d68ab;
  --stack-color: white;
  --stack-background-color: #0083c2;
  --spin-button-color: #da1b60;
  --background-color: #0d68ab;
  --spinwheel-background: #f4f4f4;
  --primary-color: #2eceff; 
  /* --secondary-color: #0d68ab; */
  --accent-color: #bfd730;
  /* --primary-text-color:#0083c2; */
  /* --stroke: #02528b; */
}




@media screen and (max-width: 6000px) {
  html {
    --responsive-font-height: 1.6rem;
  }
}


@media screen and (max-width: 1920px) {
  html {
    --responsive-font-height: 0.8rem;
  }
}

/* @media screen and (max-width: 1920px) and (orientation: portrait) {
  html {
    --responsive-font-height: 1rem;
  }
} */


@media screen and (max-width: 1024px) and (orientation: portrait) {
  html {
    --responsive-font-height: 2rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    --responsive-font-height: 1.5rem;
  }
}

@media screen and (max-width: 540px) {
  html {
    --responsive-font-height: 0.8rem;
  }
}

@media screen and (max-width: 300px) {
  html {
    --responsive-font-height: 0.8em;
  }
}





#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.background {
  background-image: linear-gradient(var(--stack-background-color), var(--background-color));
  // background-color: var(--background-color);
}

.content {
    /* height: calc(100vh - 40px); */
    height: 100%;
    font-size: var(--responsive-font-height)
    /* position: fixed; */
}

.img-size {
  height: 3em;
  // background-color: var(--background-color);
  padding: 0.8em;
  /* margin-top: 0.2em; */
}

</style>
