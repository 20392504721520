<template>
    <div id="nav" class="flex justify-center items-end">
      <router-link to="/Home" tag="div" class="btn__navleft pr-12">HOME</router-link> 
      <router-link to="/Jewels" tag="div" class="btn__navcenter">MORE JEWELS</router-link>
      <router-link to="/Prizes" tag="div" class="btn__navright">({{amountOfPrizes}}) PRIZES</router-link>
    </div>
</template>

<script>
export default {
  
computed: {
  amountOfPrizes(){
      return this.$store.getters.amountOfPrizes
    }
}

}

</script>

<style lang="scss" scoped>

.btn__navleft {
  display: flex;
  align-items: center;
  justify-content:center;
  // border-right: 1px white solid;
  padding: 10px;
  height: 4em;
  width: 100%;
  border-radius: 0px 1em 0px 0px;
  font-size: 0.8em;
  background-color: white;
  color:var(--background-color);
  font-weight: 600;
  user-select: none;
}

.btn__navcenter {
  display: flex;
  align-items: center;
  justify-content:center;
  // border-right: 1px white solid;
  padding: 10px;
  margin-left: 0.2em;
  margin-right: 0.2em;
  height: 4em;
  width: 100%;
  border-radius: 1em 1em 0em 0px;
  font-size: 0.8em;
  background-color: var(--spin-button-color);
  color:var(--spinwheel-background);
  font-weight: 600;
  user-select: none;
}

.btn__navcenter:hover {
  cursor: pointer;
}

.btn__navleft:hover {
  cursor: pointer;
}

#nav {
  font-size: var(--responsive-font-height);
}

#nav div.router-link-exact-active {
  color: var(--background-color);
  font-weight: bold;
  background-color: var(--background-color);
  color:white
}

.btn__navright {
  display: flex;
  align-items: center;
  justify-content:center;
  // border-left: 1px white solid;
  background-color: white;
  padding: 10px;
  height: 4em;
  width: 100%;
  border-radius: 1em 0px 0px 0px;
  color:var(--background-color);
  font-size: 0.8em;
  font-weight: 600;
  user-select: none;
}

.btn__navright:hover {
  cursor: pointer;
}

</style>

