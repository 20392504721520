import '@/assets/css/tailwind.css'
import '@/assets/css/main.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: { id: "UA-63300532-33" }
  },router);
}

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
