import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
    // maybe try and do animations with class bindings, to seperate the intent from the detail

export default new Vuex.Store({
  state: {
    selectJewel: [require("../../src/assets/sapphire.png"), require("../../src/assets/emerald.png"),  require("../../src/assets/ruby.png")],
    selectFlatJewel: [require("../../src/assets/jewels-flat-sapphire.png"), require("../../src/assets/jewels-flat-Emerald.png"),  require("../../src/assets/jewels-flat-Ruby.png")],
    newSubscriber: true,
    navBarShow: false,
    lookAtPrizes: false,
    availableSpins : 0,
    needStacksPopUp: false,
    showLogo: false,
    startJewelAnimation : false,
    userPrizes: [],
    availablePrizes: [
      {
        name: "200MB Data Bundle",
        expire: 'valid for 7 days'
      },
      {
        name: "Entry to win a car",
        expire: 'The lucky draw is on the 20th February 2021'
      },
      {
        name: "R5 Airtime",
        expire: 'valid for 30 days'
      },
      {
        name: "20% Discount on bundles",
        expire: 'valid for today'
      }
    ],
    
    wheelPosition: [
      {
        name: "SORRY NO PRIZE",
        deg: 22.5,
      },
      {
        name: "DATA BUNDLE",
        deg: 67.5,
      },
      {
        name: "SORRY NO PRIZE",
        deg: 112.5,
      },
      {
        name: "ENTRY TO WIN A CAR",
        deg: 157.5,
      },
      {
        name: "SORRY NO PRIZE",
        deg: 202.5,
      },
      {
        name: "DISCOUNTED BUNDLE",
        deg: 247.5,
      },
      {
        name: "SORRY NO PRIZE",
        deg: 292.5,
      },
      {
        name: "AIRTIME PRIZE",
        deg: 337.5,
      }

    ],
    stackState: [
      {state: false, selected: 0},
      {state: false, selected: 0},
      {state: false, selected: 0} 
    ],
      allStacks: [
      {
        stackName: 'Happy New Year',
        points: 150,
        expiration: 2,
        selected: false
      },
      {
        stackName: 'Christmas Points',
        points: 100,
        expiration: 1,
        selected: false
      },
      {
        stackName: 'Great Customer',
        points: 100,
        expiration: 1,
        selected: false
      },
      {
        stackName: 'Stack from Data Bundle',
        points: 50,
        expiration: 3,
        selected: false
      },
      {
        stackName: 'Extra Bacon',
        points: 50,
        expiration: 3,
        selected: false
      },
      {
        stackName: 'Extra Cheese',
        points: 50,
        expiration: 3,
        selected: false
      },
      {
        stackName: 'Extra check checky check',
        points: 50,
        expiration: 3,
        selected: false
      },
    ]
  
  },
  mutations: {
    addStack(state, selection) {
      var i = 0;
      console.log('selection :' + selection) 
      
      for(i = 0; i < state.stackState.length; i++){
           if(state.stackState[i].state === false){
             state.stackState[i].selected = selection
             state.allStacks[selection].selected = true 
             state.stackState[i].state = true
             break
             
            //  console.log('ADDED :' + selection) 
            //  console.log('ADDEDsss :' + state.stackState[i].selected) 
           }
     }  
     console.log('done')  
    },
    changeStack(state, newStack) {
      state.stackState[newStack.change].state = false
      state.allStacks[state.stackState[newStack.change].selected].selected = false
    },

    removeASpin(state) {
      console.log('removeSpin Mutation' + state.availableSpins)
      return state.availableSpins = state.availableSpins - 1
    },

    randomPrize() {
        var test = Math.floor(Math.random() * 8) + 1
        console.log('MutationRandomNumberGenerated = ' + test)
        return test
        // console.log('Mutation = ' + state.wheelPosition[randomPrize])
        // return state.wheelPosition[randomPrize]
      
    }
      
  },
  getters: {
    selectFlatJewel(state) {
      return state.selectFlatJewel
    },
    
    selectJewel(state){
      return state.selectJewel
    },
    
    showLogo(state){
      return state.showLogo
    },
    
    startJewelAnimation(state){
      return state.startJewelAnimation
    },
    
    
    newSubscriber(state){
      return state.newSubscriber
    },
    
    lookAtPrizes(state){
      return state.lookAtPrizes
    },
    
    needStacksPopUp(state) {
      return state.needStacksPopUp
    },
    
    navBarShow(state) {
      return state.navBarShow
    },
    
    allStacks(state) {
      return state.allStacks
    },
    stackState(state) {
      return state.stackState
    },
    
    userPrizes(state) {
      return state.userPrizes
    },

    availablePrizes(state){
      return state.availablePrizes
    },

    amountOfPrizes(state) {
      return state.userPrizes.length
    },

    wheelPosition(state) {
      return state.wheelPosition
    },
    
    totalPoints(state) {
      var i = 0
      var total = 0
      for(i ; i < state.stackState.length; i++) {
          if(state.stackState[i].state === true  ) {
             total = total + state.allStacks[state.stackState[i].selected].points
             state.availableSpins = total / 50
            }
      }
      return total
    },
    availableSpins(state) {
      return state.availableSpins 
    },
    
    stackSize(state) {
      var i = 0
      var size = 0
      for(i ; i < state.stackState.length; i++) {
          if(state.stackState[i].state === true  ) {
             size = size + 1
          }
      }
      return size
    },
    enableSpinButton(_, getters){
      if(getters.stackSize === 3){
        return true
      }else {
        return false
      }  
    }
    
  
  },
  
  
  actions: {
    addStack(context, selection) {
      context.commit('addStack',selection.selected)
      console.log('action')
      console.log('In actions' + selection.selected)

    },
    changeStack(context, newStack) {
      context.commit('changeStack', {change : newStack.change})
      console.log('In actions' + newStack.selected)
    },
    removeASpin(context) {
      context.commit('removeASpin')
    },
    randomPrize(context) {
      context.commit('randomPrize')
      console.log('actions = randomprize')
    }

  },

  modules: {
  }
})
