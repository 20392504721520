<template>
  <div class="flex justify-around items-center lg:px-10">

     <button  @click="navBarHide" class="new-stack-btn" :class="{'where-to-add-stacks': needStacksPopUp}">
      
      <div v-if="!isStack1Selected" class="rotate-circle"></div>
      
      <div v-if="!isStack1Selected" class="flex justify-center items-center">START</div>
      <Stack v-if="isStack1Selected"
           :index="0"
           :points='stack1.points'
           :expiration='stack1.expiration'
           :stackname='stack1.stackName'
           class="jewel-enter"
           />
      <!-- <p class="">START HERE</p> 
      <div class="img__redline"></div> -->
    </button>
    
    
     <button v-if="stackSize >= 2" @click="navBarHide" class="new-stack-btn"  :class="{'where-to-add-stacks': needStacksPopUp}">
      
       <div v-if="!isStack3Selected" class="rotate-circle"></div>
      <div v-if="!isStack3Selected" class="flex justify-center items-center">ADD</div>
      <Stack v-if="isStack3Selected"
           :index="2"
           :points='stack3.points'
           :expiration='stack3.expiration'
           :stackname='stack3.stackName'
           class="jewel-enter"           
           />
      </button>
    


    <button v-if="stackSize >= 1" @click="navBarHide" class="new-stack-btn" :class="{'where-to-add-stacks': needStacksPopUp}">
      <div v-if="!isStack2Selected" class="rotate-circle"></div>
     <div v-if="!isStack2Selected" class="flex justify-center items-center">ADD</div>
      <Stack v-if="isStack2Selected"
           :index="1"
           :points='stack2.points'
           :expiration='stack2.expiration'
           :stackname='stack2.stackName'
           class="jewel-enter"
           />
      </button>

   

   

    
  </div>


</template>

<script>
import Stack from '../molecules/Stack'
import { mapGetters } from 'vuex';

export default {
  name: 'AddStacks',
  props: {
  },

  methods: {
    navBarHide(){
    // this.$store.state.navBarShow = false
    this.$router.push('/Allstacks')
  }
    


  },

  computed: {
    ...mapGetters(['allStacks', 'stackState']),
    isStack1Selected() {
      return this.stackState[0].state; // stackState => userStackStates , state => chosenState
    },
    isStack2Selected() {
      return this.stackState[1].state;
    },
    isStack3Selected() {
      return this.stackState[2].state;
    },
    stack1() {
      return this.allStacks[this.stackState[0].selected]; // selectedIndex
    },
    stack2() {
      return this.allStacks[this.stackState[1].selected];
    },
    stack3() {
      return this.allStacks[this.stackState[2].selected];
    },

    stackSize() {
    return this.$store.getters.stackSize
    },

    needStacksPopUp(){
    return this.$store.getters.needStacksPopUp
  }

  },
  components: {
    Stack
  }
}
</script>

<style lang="scss" >

.jewel-enter{

animation: jewel-enter-animation 0.2s ease-out forwards;

}

@keyframes jewel-enter-animation {

    0% {
      transform: translate(0em,-5em);
    }

    100% {
      transform: translate(0em,0em);
    }

}

.rotate-circle {
  position: absolute;
  width:5.7em;
  height:5.7em;
  border-left: 0.2em solid var(--spin-button-color);
  border-top: 0.2em solid var(--stack-background-color);
  border-bottom: 0.2em solid var(--stack-background-color);
  border-right: 0.2em solid var(--spin-button-color);
  border-radius: 50%;
  background-color: transparent;
  animation: rotate-quarter-circle 1.5s linear infinite;
  
}

@keyframes rotate-quarter-circle {
  0% {
    transform: rotate(0deg)
  }
  
  100% {
    transform: rotate(359deg)
  }


}

.new-stack-btn {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  // padding: 0.3em;
  width: 6em;
  height: 6em;
  background-color: transparent;
  border-radius: 50%;
  // margin-left : 0.5em;
  // margin-right : 1em;
    font-weight: bold;
  font-size: 1em;
  user-select: none;
  color: white;
  // animation: showWhereToAddStacks 0.5s linear alternate infinite forwards;
  box-shadow: -0.2em -0.45em 0.6em rgba(46,206,255,1),
              -0.1em -0.25em 1em rgba(0,61,99,1),
              inset 0.1em 0.25em 0.25em rgba(46,206,255,0.1),
              0.05em 0.05em 0.75em rgba(0,61,99,1);
              
  

  &:focus {
	outline: none;
	}
  
  
  // &:hover {
  //   box-shadow: inset -2px -4px 8px rgba(46,206,255,1),
  //             inset -2px -4px 12px rgba(0,61,99,1),
  //             inset 2px 4px 4px rgba(46,206,255,0.1),
  //             inset 2px 2px 8px rgba(0,61,99,1);
  //   transform: scale(0.98);
  //   // color: transparent;
  // }

}


.where-to-add-stacks {
  animation: showWhereToAddStacks 0.25s linear forwards
}

@keyframes showWhereToAddStacks {
  
  0% {
    transform: scale(1,1);
    opacity: 1;
  }

  50% {
    transform: scale(1.05,1.05);
    opacity: 1;
  }
  
  100% {
    transform: scale(1,1);
    opacity: 1;
  }

}


.btn__addstack {
  display:flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  // border: 0.07em var(--secondary-color) solid;
  margin-top: 0.2em;
  // padding: 2px;
  height: 3em;
  width: 100%;
  border-radius: 0.8em;
  color: var(--add-stack-color);
  background-color: var(--add-stack-background);
  font-size: var(--responsive-font-height);
  font-weight: bold;
  cursor: pointer;
  transition: transform .2s ease-in-out; 
  user-select: none;

  &:focus {
	outline: none;
	}

  
}

.btn__addstack:hover {
  transform: scale(0.98, 0.98);
  
   

}

.img__redline {
  height: 0.3em;
  margin-top: -0.2em;
  width: 7em;
  background-color: var(--spin-button-color);
  border-radius: 10px 10px 10px 10px;
}

</style>
